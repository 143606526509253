@import "cms-style";
//@import "../js/plugins/lightbox/lightbox.scss";
@import "../js/plugins/chosen/chosen.scss";
@import "../js/plugins/datepicker/css/bootstrap-datepicker3";

$main_font:'Exo 2', sans-serif;

$black:#000000;
$white:#ffffff;
$accent_color1:#ed1c24;

$theme_color1:#f7f7f7;

$font_color1:$black;
$font_color2:#7e7e7e;

$border_color1:#f3f5f7;

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin anim{
    transition: 0.3s all ease;
}

body{
    font-size: 16px;
    line-height: 34px;
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        font-weight: 400;
        margin: 0;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    &.page_contrast{
        .more_btn2, .more_btn1{
            background: #000000;
            color: $white !important;
            border: 1px solid $white;
        }
        .proposal {
            > div.proposal_left{
                background: #000000;
            }
        }
        .news .grid_item article{
            background: #000000 !important;
        }
        .see_other .grid_item article{
            background: #000000 !important;
        }
        main.page_content.subpage_content{
            background: #000000;
        }
        .filters {
            > ul{
                &:after{
                    display: none;
                }
            }
        }
        .filters {
            .chosen-container-single {
                .chosen-single {
                    span{
                        color: $white;
                    }
                }
            }
        }
        .page_footer.subpage_footer{
            background: #000000;
        }
        .time_line{
            background: #000000;
        }
        .news_details .gallery_list > ul > li .gal_title{
            background: transparent !important;
        }
        .project_details .gallery_list > ul > li .gal_title{
            background: transparent !important;
        }
        .main_members .management{
            background: $white;
        }
        .main_members:after{
            display: none;
        }
        .movies {
            .grid_movies {
                > div {
                    .movie_box {
                        > div.movie_box_content{
                            background: transparent !important;
                        }
                    }
                }
            }
        }
         .btn-primary{
             color: $white !important;
         }
        .default_form .default_label{
            color: $white !important;
        }
        .page_header {
            .login_register_box {
                .btns.unlogged {
                > ul {
                    > li {
                            a{
                                color: $white;
                                border: 1px solid $white;
                                background: #000000;
                            }
                        }
                    }
                }
            }
        }
        section.login{
            background: #000000;
        }
        .dashboard {
            .your_proposals {
                .proposal_list {
                    > ul {
                        > li {
                            article{
                                background: #000000;
                            }
                        }
                    }
                }
            }
        }
        .dashboard {
            .dashboard_btn{
                background: #000000;
                color: $white;
                border: 1px solid $white;
            }
        }
        .expense_table_container {
            .table_head_tr {
                th{
                    color: $white;
                    background: #000000;
                    border: 1px solid $white;
                }
            }
        }
        .fundators {
            .fundators_list {
                > ul {
                    > li{
                        border: none !important;
                    }
                }
            }
        }
        div, article{
            box-shadow: none !important;
        }
        .share_banner {
            .banner_content {
                > ul {
                    > li.banner_left_side{
                        background-image: none;
                    }
                }
            }
        }
        .time_line{
            .time_line_line {
                > ul {
                    > li {
                        .time_line_content {
                            .image_holder {
                                .image {
                                    .cover{
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .time_line {
            .time_line_line{
                > ul {
                    > li {
                        .time_line_content {
                            > div.date_holder {
                                .date {
                                    .default_font{
                                        background: transparent !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .more_btn4{
            &.red{
                background: #000000 !important;
                border: 1px solid $white;
            }
        }
    }
}

.more_btn1{
    display: inline-block;
    white-space: nowrap;
    line-height: 70px;
    position: relative;
    background: #eaebeb;
    @include anim;
    overflow: visible;
    @media only screen and (max-width: 991px) {
        width: 100%;
        text-align: center;
    }
    p{
        display: inline-block;
        padding-right: 38px;
        @include anim;
        font-size:12px;
        color: $black;
        position: relative;
        left: -38px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("/img/more_arr1.svg");
        font-weight: 700;
        @media only screen and (max-width: 991px) {
            left: 0;
        }
    }
}

.more_btn2{
    display: inline-block;
    background: $white;
    padding: 0 33px;
    line-height: 50px;
    font-size: 12px;
    text-transform: uppercase;
    @include anim;
    &:hover{
        &:after, &:before{
            height: 100%;
            width: 50px;
            opacity: 1;
        }
    }
    &:after, &:before{
        opacity: 0;
    }
    &:before{
        content: '';
        position: absolute;
        left: -15px;
        top: -15px;
        height: 0%;
        width: 0px;
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        @include anim;
    }
    &:after{
        content: '';
        position: absolute;
        right: -15px;
        bottom: -15px;
        height: 0%;
        width: 0px;
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        @include anim;
    }
    &.red{
        background: $accent_color1;
        strong{
            color: $white;
            background-image: url("/img/read_up_arr2.png");
        }
        &:before{
            border-top: 1px solid $accent_color1;
            border-left: 1px solid $accent_color1;
        }
        &:after{
            border-bottom: 1px solid $accent_color1;
            border-right: 1px solid $accent_color1;
        }
    }
    &.green{
        background: #92cb33;
        &.prev{
            strong{
                background-position: left center;
                padding-left: 24px;
                padding-right: 0;
                background-image: url("/img/read_up_arr2_left.png");
            }
        }
        strong{
            color: $white;
            background-image: url("/img/read_up_arr2.png");
        }
        &:before{
            border-top: 1px solid #92cb33;
            border-left: 1px solid #92cb33;
        }
        &:after{
            border-bottom: 1px solid #92cb33;
            border-right: 1px solid #92cb33;
        }
    }
    &.no_arr{
        border: 1px solid #e4e4e4;
        strong{
            background-image: none;
            padding-right: 0;
        }
    }
    &.grey{
        background: #e4e4e4;
        border: 1px solid #e4e4e4;
        &:before{
            border-top: 1px solid #e4e4e4;
            border-left: 1px solid #e4e4e4;
        }
        &:after{
            border-bottom: 1px solid #e4e4e4;
            border-right: 1px solid #e4e4e4;
        }
        strong{
            background-image: url("/img/read_up_arr3.png");
        }
    }
    &:hover{
        text-decoration: none;
    }
    strong{
        color: $font_color1;
        background-image: url("/img/read_up_arr.png");
        padding-right: 24px;
        background-repeat: no-repeat;
        background-position: right center;
    }
    &.back{
        strong{
            background-position: left center;
            padding-right: 0;
            padding-left: 24px;
            background-image: url("/img/back_arr_white.png");
        }
    }
}

.more_btn3{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;
    color: $white;
}

.more_btn4{
    display: inline-block;
    background: $white;
    padding: 0 33px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    &.red{
        background: $accent_color1;
        strong{
            color: $white;
            background-image: url("/img/read_up_arr2.png");
        }
    }
    &.no_arr{
        border: 1px solid #e4e4e4;
        strong{
            background-image: none;
            padding-right: 0;
        }
    }
    &.grey{
        background: #e4e4e4;
        border: 1px solid #e4e4e4;
        strong{
            background-image: url("/img/read_up_arr3.png");
        }
    }
    &:hover{
        text-decoration: none;
    }
    strong{
        color: $font_color1;
        background-image: url("/img/read_up_arr.png");
        padding-right: 24px;
        background-repeat: no-repeat;
        background-position: right center;
    }
}

.read_up_btn{
    background-image: url("/img/read_up_arr.png");
    padding-right: 24px;
    background-repeat: no-repeat;
    background-position: right center;
    @media only screen and (max-width: 991px) {
        text-align: right;
    }
}

.default_font{
    font-size: 14px;
    line-height: 30px;
    h1{
        font-size: 110px;
        line-height: 122px;
        @media only screen and (max-width: 1500px) {
            font-size: 60px;
            line-height: 72px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 36px;
            line-height: 48px;
        }
    }
    h2{
        font-size: 60px;
        line-height: 72px;
        @media only screen and (max-width: 1500px) {
            font-size: 48px;
            line-height: 60px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 30px;
            line-height: 42px;
        }
    }
    h3{
        font-size: 48px;
        line-height: 60px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 36px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 36px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 33px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 33px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 30px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    ol,li{
        h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
            margin: 0;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
    a{
        color: $accent_color1;
    }
    strong{
        font-weight: 700;
    }
}

.page{
    background-repeat: no-repeat;
    background-position: left bottom;
    @media only screen and (max-width: 991px) {
        background-image: none !important;
    }
    &.subpage{
        .default_font{
            h1{
                font-size: 60px;
                line-height: 72px;
                @media only screen and (max-width: 1500px) {
                    font-size: 48px;
                    line-height: 60px;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 30px;
                    line-height: 42px;
                }
            }
            h2{
                font-size: 48px;
                line-height: 60px;
                @media only screen and (max-width: 1500px) {
                    font-size: 24px;
                    line-height: 36px;
                }
            }
            h3{
                font-size: 24px;
                line-height: 36px;
                @media only screen and (max-width: 1500px) {
                    font-size: 21px;
                    line-height: 33px;
                }
            }
            h4{
                font-size: 21px;
                line-height: 33px;
                @media only screen and (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            h5{
                font-size: 18px;
                line-height: 30px;
                @media only screen and (max-width: 1500px) {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            h6{
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
}

.page_header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    @media only screen and (max-width: 991px) {
        background: $font_color1;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: $accent_color1;
        }
        .header_content{
            padding-top: 8px;
        }
    }
    &.subpage_header{
        @media only screen and (max-width: 991px) {
            background: #f7f7f7;
        }
        &.smaller{
            .header_holder{
                background: #f7f7f7;
            }
            .header_content{
                border-bottom: 1px solid transparent;
                @media only screen and (max-width: 991px) {
                    border-bottom: none;
                }
            }
        }
        .header_content{
            @include anim;
            border-bottom: 1px solid #cccccc;
            @media only screen and (max-width: 991px) {
                border-bottom: none;
            }
        }
        .menu_box {
            .menu {
                > ul {
                    > li{
                        div{
                            a{
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
        .btn-primary{
            color: $black;
        }
        .login_register_box {
            .btns {
                >ul{
                    >li {
                        a{
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    &.smaller{
        .header_holder{
            background: $font_color1;
        }
    }
    .progress_bar{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 3px;
        .pb{
           background: $accent_color1;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
        }
    }
    .header_holder{
        @include anim;
        height: 105px;
        @media only screen and (max-width: 991px) {
            height: auto;
        }
    }
    .logo_box{
        position: relative;
        @media only screen and (max-width: 991px) {
            img{
                max-width: 100px;
            }
        }
        .rwd_btn{
            top: 15px;
            right: 0;
            width: 30px;
            position: absolute;
            height: 18px;
            cursor: pointer;
            overflow: hidden;
            display: none;
            z-index: 9999;
            @media only screen and (max-width: 991px) {
                display: block;
                &.menu_open{
                    > div{
                        &:nth-child(1){
                            width: 100%;
                            left: 0;
                            transform: translate(0px, 8px) rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 100%;
                        }
                        &:nth-child(3){
                            width: 100%;
                            transform: translate(0px, -8px) rotate(-45deg);
                        }
                    }
                }
            }
            > div{
                position: absolute;
                height: 2px;
                background: $accent_color1;
                @include anim;
                &:nth-child(1){
                    left: 10px;
                    width: 20px;
                    top: 0;
                }
                &:nth-child(2){
                    top: 8px;
                    left: 0;
                    width: 100%;
                }
                &:nth-child(3){
                    top: 16px;
                    left: 0;
                    width: 20px;
                }
            }
        }
    }
    .navigation{
        text-align: right;
        padding-top: 33px;
        @media only screen and (max-width: 1500px) {
            padding-top: 25px;
        }
        @media only screen and (max-width: 1300px) {
            padding-top: 17px;
        }
        @media only screen and (max-width: 1200px) {
            padding-top: 10px;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
            display: none;
            padding-bottom: 30px;
            @include anim;
            opacity: 0;
            visibility: hidden;
            padding-top: 50px;
            &.menu_active{
                padding-top: 14px;
                opacity: 1;
                visibility: visible;
            }
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                //margin-right: 48px;
                margin-right: 35px;
                @media only screen and (max-width: 1500px) {
                    margin-right: 12px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                }
                &.langs_box{
                    margin-right: 25px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 13px;
                        width: auto;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 0;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: auto;
                    }
                }
                &.search_box{
                    cursor: pointer;
                    margin-right: 36px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 18px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 20px;
                        width: auto;
                    }
                }
                &.social_box{
                    @media only screen and (max-width: 1200px) {
                        img{
                            max-width: 19px;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        width: auto;
                        margin-right: 15px;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                &.availability{
                    @media only screen and (max-width: 1400px) {
                        //margin-right: 0;
                    }
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 20px;
                            @media only screen and (max-width: 1500px) {
                                margin-right: 10px;
                            }
                            .plugin_font_sizes {
                            > li {
                                vertical-align: baseline;
                                line-height: 18px !important;
                                &.font_large{
                                    a{
                                        font-size: 18px;
                                    }
                                }
                                &.font_normal{
                                    a{
                                        font-size: 13px;
                                    }
                                }
                                &.font_small{
                                    a{
                                        font-size: 9px;
                                    }
                                }
                                a{
                                    color: #a9a9a9;

                                }
                            }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .menu_box{
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .menu{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 16px;
                    //margin-right: 55px;
                    //@media only screen and (max-width: 1500px) {
                    //    margin-right: 40px;
                    //}
                    //@media only screen and (max-width: 1400px) {
                    //    margin-right: 26.5px;
                    //}
                    @media only screen and (max-width: 1300px) {
                        margin-right: 18px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 10px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                        text-align: center;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    div{
                        &:hover{
                            a{
                                text-decoration: none;
                                &:after{
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                        &.on{
                            a{
                                color: $accent_color1;
                                &:after{
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                        a{
                            font-size: 13px;
                            line-height: 13px;
                            text-transform: uppercase;
                            font-weight: 600;
                            color: $white;
                            position: relative;
                            @include anim;
                            @media only screen and (max-width: 1400px) {
                                font-size: 12px;
                                line-height: 12px;
                            }
                            @media only screen and (max-width: 1300px) {
                                font-size: 10px;
                                line-height: 10px;
                            }
                            @media only screen and (max-width: 1200px) {
                                font-size: 8px;
                                line-height: 8px;
                            }
                            &:after{
                                content: '';
                                position: absolute;
                                top: calc(100% + 56px);
                                width: 0%;
                                border-bottom: 1px solid $accent_color1;
                                left: 50%;
                                @include anim;
                                @media only screen and (max-width: 1200px) {
                                    top: calc(100% + 33px);
                                }
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                font-size: 20px;
                                line-height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
    .social_box{
        .social{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .langs_box{
        .dropdown {
            &.focused{
                border: 2px solid #23527c;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px;
            }
            &.open{
                .dropdown-menu{
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .btn{
            font-size: 11px;
            padding: 6px 0;
            &.btn-primary{
                background: transparent;
                border: none;
            }
        }
        .dropdown-menu{
            //width: 100%;
            text-align: center;
            min-width: 0;
            font-size: 11px;
            display: block;
            @include anim;
            top: 50%;
            opacity: 0;
            visibility: hidden;
            > li{
                a{
                    padding: 3px 5px;
                    &:hover{
                        color: $white;
                        background: $accent_color1;
                    }
                }
            }
        }
    }
    .login_register_box{
        @media only screen and (max-width: 991px) {
            padding-top: 30px;
        }
        .btns{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 30px;
                    @media only screen and (max-width: 1400px) {
                        margin-right: 7px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 50%;
                    }
                   a{
                       padding: 0 14px;
                       font-size: 9px;
                       line-height: 30px;
                       display: inline-block;
                       letter-spacing: 2px;
                       //@media only screen and (max-width: 1200px) {
                       //    font-size: 8px;
                       //    line-height: 8px;
                       //}
                       @media only screen and (max-width: 1200px) {
                           font-size: 8px;
                       }
                       @media only screen and (max-width: 991px) {
                           display: block;
                           font-size: 10px;
                       }
                   }
                }
            }
            &.unlogged{
                > ul{
                    > li{
                        a{
                            text-transform: uppercase;
                            font-weight: 700;
                            color: $white;
                            line-height: 30px;
                            border-radius: 5px;
                            &.proposal_btn{
                                color: $white;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                            a{
                                background: $accent_color1;
                            }
                        }
                    }
                }
            }
            &.logged{
                .you_acc{
                    cursor: pointer;
                    position: relative;
                    &:hover{
                        ul{
                            top: calc(100% + 15px);
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    p{
                        padding-right: 22px;
                        background-repeat:no-repeat;
                        background-position: right center;
                        background-image: url("/img/login_small_arr.png");
                        display: inline-block;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 12px;
                        &.white_acc{
                            color: $white;
                            background-image: url("/img/login_small_arr_w.png");
                        }
                    }
                    ul{
                        @include anim;
                        position: absolute;
                        top: 50%;
                        opacity: 0;
                        visibility: hidden;
                        right: 0;
                        background: $white;
                        padding: 5px 15px;
                        border: 1px solid $border_color1;
                        li{
                            border-bottom: 1px solid $border_color1;
                            white-space: nowrap;
                            &:last-child{
                                border-bottom: none;
                            }
                            a{
                                font-size: 12px;
                                padding: 0;
                                font-weight: 700;
                                color: $font_color1;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}

.slider{
    height: 100vh;
    width: 100%;
    position: relative;
    > ul{
        @include fill;
        > li{
            @include fill;
            background: $black;
            overflow: hidden;
            .image{
                opacity: 0.4;
                @include fill;
                background-size: cover;
                @include bg_pos;
            }
            .slider_content{
                z-index: 2;
                @include fill;
                @include vertical;
                color: $white;
                .slide_header, .slide_desc{
                    margin-bottom: 90px;
                    @media only screen and (max-width: 1500px) {
                        margin-bottom: 45px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 30px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 15px;
                    }
                }
                .slide_header{
                    .default_font{
                        strong  {
                            font-weight: 600;
                        }
                    }
                }
                .slide_desc{
                    @media only screen and (max-width: 991px) {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
                .go_to{
                    @include anim;
                    &:hover{
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
    .slider_nav{
        .slider_nav_top{
            @include fill;
            @include vertical;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .slide_nav_holder{
            position: relative;
            width: 100%;
        }
        .slide_small_view{
            position: absolute;
            top: 0;
            //width: 337px;
            //height: 166px;
            width: 17.5%;
            height: 0;
            padding-bottom: 8.66%;
            z-index: 2;
            @media only screen and (max-width: 1500px) {
                width: 23.5%;
                height: 0;
                padding-bottom: 10.66%;
            }
            &:after{
                content: '';
                border-bottom: 2px solid rgba(255,255,255,0.3);
                position: absolute;
                top: 50%;
                width: 100%;
                z-index: -1;
                @include anim;
            }
            .image{
                @include bg_pos;
                background-size: cover;
                height: 100%;
               // width: 100%;
                width: 69%;
                z-index: 2;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                position: absolute;
                .cover{
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    @include anim;
                    background: rgba(237,28,36,0.7);
                    @include vertical;
                    > div{
                        width: 100%;
                        p{
                            display: inline-block;
                            vertical-align: top;
                            text-transform: uppercase;
                            color: $white;
                            background-repeat: no-repeat;
                            background-size: 37px;
                        }
                    }
                }
            }
            &.prev_slide{
                left: 0;
                //padding-right: 80px;
                padding-right: 4%;
                .image{
                    left: 0;
                    .cover{
                        left: 100%;
                        > div{
                            text-align: right;
                            padding-right: 22px;
                            p{
                                padding-left: 56px;
                                background-position: left center;
                                background-image: url('/img/sl_arr_next.svg');
                            }
                        }
                    }
                }
                &:hover{
                    .cover{
                        left: 0;
                    }
                    &:after{
                        right: -50%;
                        border-bottom: 2px solid rgba(255,255,255,1);
                    }
                }
                &:after{
                    right: 0;
                }
            }
            &.next_slide{
                right: 0;
                //padding-left: 80px;
                padding-left: 4%;
                .image{
                    right: 0;
                    .cover{
                        right: 100%;
                        > div{
                            text-align: left;
                            padding-left: 22px;
                            p{
                                padding-right: 56px;
                                background-position: right center;
                                background-image: url('/img/sl_arr_prev.svg');
                            }
                        }
                    }
                }
                &:hover{
                    .cover{
                        right: 0;
                    }
                    &:after{
                        left: -50%;
                        border-bottom: 2px solid rgba(255,255,255,1);
                    }
                }
                &:after{
                    left: 0;
                }
            }
        }
        .slider_nav_bottom{
            height: 78px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            display: table;
            z-index: 3;
            @media only screen and (max-width: 991px) {
                //height: 50px;
                height: auto;
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                &.dott_side{
                    //width: calc(100% - 330px);
                    width: 100%;
                    text-align: center;
                    border-top: 1px solid rgba(255,255,255,0.3);
                    @media only screen and (max-width: 991px) {
                            display: none;
                    }
                    ul{
                        margin-top: -30px;
                        li{
                            display: inline-block;
                            vertical-align: top;
                            text-align: center;
                            //margin-right: 130px;
                            width: 15%;
                            cursor: pointer;
                            &:last-child{
                                margin-right: 0;
                            }
                            &.on{
                                .dott{
                                    border: 2px solid $accent_color1;
                                    background: $accent_color1;
                                }
                                .dott_desc{
                                    color: $white;
                                }
                            }
                            .dott{
                                display: block;
                                margin: 0 auto;
                                width: 12px;
                                height: 0;
                                padding-bottom: 8px;
                                border-radius: 50%;
                                background: $black;
                                border: 2px solid rgba(255,255,255,0.4);
                                margin-bottom: 15px;
                                @include anim;
                            }
                            .dott_desc{
                                font-size: 12px;
                                text-transform: uppercase;
                                font-weight: 700;
                                color: rgba(255,255,255,0.4);
                                @include anim;
                                p{
                                    font-weight: inherit;
                                }
                            }
                        }
                    }
                }
                &.arr_side{
                    width: 330px;
                    background: $theme_color1;
                    text-align: center;
                    display: none;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        display: block;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 27px;
                            cursor: pointer;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 50%;
                                text-align: center;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .sl_arr_btn{
                height: 9px;
                width: 39px;
                @include bg_pos;
                @include anim;
                cursor: pointer;
                @media only screen and (max-width: 991px) {
                    margin: 0 auto;
                }
                &.left{
                    background-image: url("/img/sl_arr_left.svg");
                    &:hover{
                        background-image: url("/img/sl_arr_left_red.svg");
                    }
                }
                &.right{
                    background-image: url("/img/sl_arr_right.svg");
                    &:hover{
                        background-image: url("/img/sl_arr_right_red.svg");
                    }
                }
            }
        }
    }
}

main.page_content{
    //background: $theme_color1;
    &.subpage_content{
        padding-top: 215px;
        background-position: left top;
        background-repeat: no-repeat;
        background-image: url("/img/top_bg.jpg");
        min-height: 480px;
        @media only screen and (max-width: 1500px) {
            padding-top: 190px;
        }
        @media only screen and (max-width: 1200px) {
            padding-top: 130px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 95px;
            background-image: none;
        }
    }
}

.news{
    padding-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        padding: 85px 0 60px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0 50px 0;
    }
}

.see_other{
    padding-bottom: 170px;
    @media only screen and (max-width: 1500px) {
        padding: 85px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0 50px 0;
    }
}

.news, .see_other{
    padding-top: 165px;
    background: $theme_color1;

    &.project_news{
        padding-top: 110px;
        padding-bottom: 120px;
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            padding-top: 55px;
            padding-bottom: 60px;
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 50px 0;
            margin-bottom: 30px;
        }
    }
    &.main_news{
        padding-top: 0;
        background: transparent;
        article{
            border: 1px solid #f8f8f8;
        }
    }
    .news_grid_list{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
        grid-gap: 58px;
        grid-auto-rows: minmax(251px, auto);
        grid-auto-flow: dense;
        @media only screen and (max-width: 1500px) {
            grid-gap: 28px;
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        .grid_item{
            &.focused{
                border: 2px solid #23527c;
            }
        }
        a{
            display: block;
            height: 100%;
            width: 100%;

        }
    }
    .red_box{
        display: none !important;
    }
    .image_half{
        .red_box{
            @include anim;
        }
        &:hover{
            .red_box{
                height: 100% !important;
            }
        }
    }
    .image_full{
        &:hover{
            .red_box{
                > div{
                    > div{
                        transform: scale(1.15);
                    }
                }
            }
        }
        .red_box{
            > div{
                > div{
                    @include anim;
                }
            }
        }
    }


    .grid_item{
        position: relative;
        //@include anim;
        background: $theme_color1;
        //&:hover{
        //    -webkit-box-shadow: inset 0px 0px 40px 0px rgba(219,218,218,0.61);
        //    -moz-box-shadow: inset 0px 0px 40px 0px rgba(219,218,218,0.61);
        //    box-shadow: inset 0px 0px 40px 0px rgba(219,218,218,0.61);
        //}
        article{
            @include fill;
            background:$white;
        }
        &.image_half{
            grid-column-end: span 1;
            grid-row-end: span 2;
            .image{
                height: 44.822%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                @include bg_pos;
                background-size: cover;
                @media only screen and (max-width: 991px) {
                    background-size: contain;
                }
                .red_box{
                    width: 76%;
                    height: 59px;
                    background-clip: content-box;
                    background-origin: content-box;
                    background: $accent_color1;
                    mix-blend-mode: multiply;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    @media only screen and (max-width: 991px) {
                        left: 15px;
                        width: calc(100% - 30px);
                        right: auto;
                        margin: auto;
                    }

                }
            }
            .desc{
                height: 55.178%;
                position: absolute;
                top: 44.822%;
                left: 0;
                width: 100%;
                .short_desc{
                    padding: 30px 40px;
                    @media only screen and (max-width: 991px) {
                        padding: 30px 15px;
                    }
                    header{
                        margin-bottom: 20px;
                        h3{
                            font-size: 24px;
                            line-height: 30px;
                            color: $font_color1;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .default_font{
                        color: $font_color2;
                        p{
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .more_bar{
                    width: 100%;
                    padding: 0 40px;
                    position: absolute;
                    left: 0;
                    bottom: 40px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 15px;
                        bottom: 20px;
                    }
                    .more_holder{
                        padding-top: 20px;
                        border-top: 1px solid #e8ebee;
                        font-size: 11px;
                        line-height: 11px;
                        color: #b7b7b7;
                    }
                }
            }
        }
        &.image_full{
            grid-column-end: span 1;
            grid-row-end: span 2;
            .image{
                @include fill;
                @include bg_pos;
                background-size: cover;
                @media only screen and (max-width: 991px) {
                    background-size: contain;
                }
                .red_box{
                    @include fill;
                    @include vertical;
                    > div{
                        width: 76%;
                        margin: 0 auto;
                        @media only screen and (max-width: 991px) {
                            width: calc(100% - 30px);
                        }
                        > div{
                            height: 160px;
                            width: 100%;
                            background-clip: content-box;
                            background-origin: content-box;
                            background: $accent_color1;
                            mix-blend-mode: multiply;
                        }
                    }
                }
                .desc{
                    @include fill;
                    .cover{
                        @include fill;
                        background: rgba(0,0,0,0);
                        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.75)));
                        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                        background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
                    }
                    .short_desc{
                        padding: 30px 40px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 72px;
                        @media only screen and (max-width: 991px) {
                            padding: 30px 15px;
                        }
                        header{
                            h3{
                                font-size: 24px;
                                line-height: 30px;
                                color: $white;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .default_font{
                            color: $white;
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    .more_bar{
                        width: 100%;
                        padding: 0 40px;
                        position: absolute;
                        left: 0;
                        bottom: 40px;
                        @media only screen and (max-width: 991px) {
                            padding: 0 15px;
                            bottom: 20px;
                        }
                        .more_holder{
                            padding-top: 20px;
                            border-top: 1px solid rgba(255,255,255,0.5);
                            font-size: 11px;
                            line-height: 11px;
                            color: rgba(255,255,255,0.5);
                        }
                    }
                }
            }
        }
        &.no_image{
            grid-column-end: span 1;
            grid-row-end: span 1;
            .desc{
                .short_desc{
                    padding: 30px 40px;
                    @media only screen and (max-width: 991px) {
                        padding: 30px 15px;
                    }
                    header{
                        margin-bottom: 20px;
                        h3{
                            //font-size: 24px;
                            font-size: 20px;
                            line-height: 30px;
                            color: $font_color1;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .default_font{
                        color: $font_color2;
                        line-height: 20px;
                        p{
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .more_bar{
                    width: 100%;
                    padding: 0 40px;
                    position: absolute;
                    left: 0;
                    bottom: 40px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 15px;
                        bottom: 20px;
                    }
                    .more_holder{
                        padding-top: 20px;
                        border-top: 1px solid #e8ebee;
                        font-size: 11px;
                        line-height: 11px;
                        color: #b7b7b7;
                    }
                }
            }
        }
    }
    //article{
    //    transform-style: preserve-3d;
    //
    //     .short_desc{
    //        transform: translateZ(20px);
    //    }
    //}
}

.section_header{
    margin-bottom: 80px;
    color: $font_color1;
    text-transform: uppercase;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
        text-align: center;
        .more_btn1{
            margin-top: 20px;
        }
    }
    .date{
        margin-left: auto;
        width: 160px;
        height: 160px;
        @include vertical;
        background: $accent_color1;
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin-top: 30px;
        }
        > div{
            width: 100%;
            text-align: center;
        }
        .day{
            font-size: 72px;
            line-height: 72px;
            margin-bottom: 5px;
            color: $white;
            @media only screen and (max-width: 991px) {
                font-size: 50px;
                line-height: 50px;
            }
        }
        .mounth{
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: rgba(255,255,255,0.5);
            letter-spacing: 1px;
            p{
                font-weight: inherit;
            }
        }
    }
    &.white{
        div.default_font{
            color: $white;
        }
    }
    &.red{
        div.default_font{
            color: $accent_color1;
        }
    }
}

.projects{
    height: 100vh;
    width: 100%;
    display: flex;
    background: $theme_color1;
    @media only screen and (max-width: 991px) {
        display: block;
        height: auto;
    }
    > div{
        &.projects_nav{
            width: 240px;
            @include vertical;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            > div{
                width: 100%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }
                .nav_box{
                    h3{
                        margin-bottom: 50px;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 0;
                        }
                    }
                    ul{
                        @media only screen and (max-width: 991px) {
                            margin-top: -35px;
                        }
                        li{
                            margin-bottom: 27px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 0;
                                width: 50%;
                                display: inline-block;
                                vertical-align: middle;
                                img{
                                    max-width: 36px;
                                    height: auto;
                                }
                                &:first-child{
                                    text-align: left;
                                    padding-left: 15px;
                                }
                                &:last-child{
                                    text-align: right;
                                    padding-right: 15px;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                            img{
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        &.project_content{
            overflow: hidden;
            width: calc(100% - 240px);
            background: $black;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            ul{
                white-space: nowrap;
                position: relative;
                height: 100%;
                @media only screen and (max-width: 991px) {
                    height: 70vh;
                }
                > li{
                    height: 100%;
                    display: inline-block;
                    vertical-align: top;
                    width: 29%;
                    position: relative;
                    @include anim;
                    @media only screen and (max-width: 1300px) {
                        width: 33.33%;
                    }
                    @media only screen and (max-width: 1200px) {
                        width: 50%;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    a{
                        height: 100%;
                        width: 100%;
                        display: block;
                        &:focus{
                            border: 2px solid #23527c;
                        }
                    }
                    &:hover{
                        width: 43.5%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &:hover{
                        .project{
                            .image{
                                opacity: 1;
                            }
                        }
                    }
                    .project{
                        @include fill;
                        .image{
                            @include fill;
                            @include anim;
                            @include bg_pos;
                            background-size: cover;
                            opacity: 0.5;
                        }
                        .desc{
                            @include fill;
                            @include vertical;
                            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
                            > div{
                                width: 100%;
                                padding: 0 80px;
                                color: $white;

                                white-space: normal;

                                @media only screen and (max-width: 1400px) {
                                    padding: 0 40px;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding: 0 15px;
                                    text-align: center;
                                }
                                h3{
                                    margin-bottom: 10px;
                                    @media only screen and (max-width: 1500px) {
                                        margin-bottom: 0;
                                    }
                                }
                                h4{
                                    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
                                    font-weight: 300;
                                }
                                p{
                                    padding-top: 50px;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    color: $accent_color1;
                                    text-transform: uppercase;
                                    @media only screen and (max-width: 991px) {
                                        padding-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.proposal{
    display: table;
    min-height: 720px;
    width: 100%;
    margin-top: -130px;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 1500px) {
        min-height: 620px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 0;
        display: block;
    }
    > div{
        display: table-cell;
        height: 100%;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        &.proposal_left{
            vertical-align: middle;
            width: 75%;
            background-color: $accent_color1;
            @include bg_pos;
            background-image: url("/img/dott_map.jpg");
            background-size: auto 100%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 50px 0;
            }
            .section_header{
                margin-bottom: 40px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
            }
            .short_desc{
                padding-left: 17%;
                padding-right: 22%;
                @media only screen and (max-width: 1500px) {
                    padding-right: 20%;
                }
                @media only screen and (max-width: 991px) {
                    padding: 0 15px;
                }
                .desc{
                    color: $white;
                    font-size: 18px;
                    margin-bottom: 60px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                        text-align: center;
                    }
                }
            }
            .btns{
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 55px;
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            margin-bottom: 15px;
                            width: 100%;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &.proposal_right{
            vertical-align: top;
            width: 25%;
            padding-top: 130px;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-top: 0;
            }
            .image{
                margin-left: -160px;
                width: calc(100% + 160px);
                height: 0;
                padding-bottom: 93%;
                @include bg_pos;
                background-size: cover;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}

.about_pfn_short{
    padding: 120px 0;
    text-align: center;
    @media only screen and (max-width: 1500px) {
        padding: 60px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 50px 0 10px 0;
    }
    .section_header{
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    .desc{
        color: $font_color1;
        .default_font{
            color: $font_color2;
            padding-top: 30px;
        }
    }
    .counts{
        position: relative;
        z-index: 3;
        margin-top: 120px;
        @media only screen and (max-width: 991px) {
            margin-top: 30px;
        }
        .flags{
            @include fill;
            .flag{
                position: absolute;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                &.flag1{
                    left: -60px;
                    bottom: 106px;
                    z-index: -1;
                }
                &.flag2{
                    right: -69px;
                    bottom: -80px;
                }
                &.flag3{
                    top: -38px;
                    right: -54px;
                }
                &.flag4{
                    top: -73px;
                    right: -128px;
                    z-index: -1;
                }
            }
        }
        ul{
            margin: 0 -20px;
            li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                padding: 0 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        .count_box{
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border: 1px solid #f3f5f7;
            position: relative;
            background: $white;
            > div.count_box_content{
                @include fill;
                @include vertical;
                > div{
                    width: 100%;
                    .number{
                        font-size: 160px;
                        line-height: 160px;
                        color: $font_color1;
                        font-weight: 600;
                        margin-bottom: 20px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 80px;
                            line-height: 80px;
                        }
                        p{
                            font-weight: inherit;
                        }
                    }
                    .default_font{
                        color:$font_color2;
                        text-transform: uppercase;
                        font-weight: 600;
                        p{
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
}

.fundators{
    &.main_fundators{
        padding-bottom: 190px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 90px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 50px;
        }
        .fundators_list{
            overflow: hidden;
            > ul{
                border-top:none;
                border-left: none;
                position: relative;
                right: -1px;
                bottom: -1px;
                > li{
                    width: 25%;
                    @media only screen and (max-width: 991px) {
                        width: 50%;
                    }
                    a{
                        display: block;
                        &:focus{
                            border: 2px solid #23527c;
                        }
                    }
                    &:hover{
                        .fundator_box{
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
    .fundators_list{
        > ul{
            border-top: 1px solid #e5e8eb;
            border-left: 1px solid #e5e8eb;
            > li{
                display: inline-block;
                vertical-align: top;
                width: calc(100% / 6);
                border-right: 1px solid #e5e8eb;
                border-bottom: 1px solid #e5e8eb;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                a{
                    display: block;
                    &:focus{
                        border: 2px solid #23527c;
                    }
                }
                .fundator_box{
                    background-color: $white;
                    width: 100%;
                    height: 0;
                    padding-bottom: 69.58%;
                    background-size: contain;
                    @include bg_pos;
                    @include anim;
                    position: relative;
                    &:hover{
                        z-index: 2;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(224,223,223,1);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(224,223,223,1);
                        box-shadow: 0px 0px 20px 0px rgba(224,223,223,1);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.footer_menu_holder{
    padding-top: 38px;
    @media only screen and (max-width: 991px) {
        padding-top: 0;
        text-align: left;
        margin-bottom: 30px;
        .menu{
            margin-bottom: 30px;
        }
    }
    > ul{
        > li{
            display: inline-block;
            vertical-align: middle;
            @media only screen and (max-width: 991px) {
                width: 100%;
                &:last-child{
                    width: 100%;
                    text-align: center;
                    a{
                        display: inline-block;
                        padding: 0 33px;
                        line-height: 50px;
                        font-size: 12px;
                        text-transform: uppercase;
                        @include anim;
                        width: 100%;
                        background: $accent_color1;
                        color: $white;
                        &:hover{
                            color: $white;
                        }
                    }
                }
            }
            a{
                font-size: 11px;
                font-weight: 700;
                color: $font_color2;
                @include anim;
                @media only screen and (max-width: 991px) {
                    font-size: 15px;
                }
                &:hover{
                    color: $accent_color1;
                    text-decoration: none;
                }
            }
        }
    }
}

.page_footer{
    position: relative;
    z-index: 3;
    padding: 69px 0;
    //background: $theme_color1;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    &.subpage_footer{
        background:#f7f7f7;
    }
    .footer_top{
        .footer_left_side{
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 80px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    .copyright{
                        margin-bottom: 30px;
                        font-size: 12px;
                        color: $font_color2;
                        p{
                            font-weight: 600;
                        }
                        span{
                            font-weight: 600;
                            color: #515a61;
                            margin-right: 30px;
                        }
                    }
                }
            }
            .logo_box{
                margin-left: -29px;
            }
        }
        .menu{
            > ul{
                @media only screen and (max-width: 991px) {
                    &:last-child{
                        text-align: center;
                        li{
                            width: 100%;
                            padding-top: 10px;
                        }
                        a{
                            display: block;
                            background: $accent_color1;
                            width: 100%;
                            padding: 7px;
                            color: $white;
                        }
                    }
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 40px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 50%;
                        text-align: center;
                    }
                }
            }
        }
    }
    .footer_bottom{
        .footer_left_side{
            @media only screen and (max-width: 991px) {
                margin-bottom: 40px;
                text-align: center;
                padding-top: 20px;
            }
            >li{
                display: inline-block;
                vertical-align: top;
                margin-right: 100px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 50%;
                    text-align: left;
                }
                &:last-child{
                    margin-right: 0;
                    padding-left: 45px;
                    border-left: 1px solid #d8d8d8;
                    @media only screen and (max-width: 991px) {
                        border-left: none;
                        text-align: right;
                        padding-left: 0;
                        //line-height: 43px;
                        line-height: 34px;
                    }
                }
            }
        }
        .to_up{
            font-size: 11px;
            line-height: 22px;
            padding-right: 30px;
            background-position: right center;
            background-repeat: no-repeat;
            cursor: pointer;
            background-image: url('/img/to_up_arr.svg');
            text-transform: uppercase;
            display: inline-block;
            color: #515a61;
            font-weight: 600;
            margin-bottom: 50px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0px;
            }
        }
        .devs{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    color: #515a61;
                    font-size: 12px;
                    line-height: 12px;
                    a{
                        color: inherit;
                    }
                }
            }
        }
    }
    .fast_info{
        font-size: 12px;
        line-height: 30px;
        color: $font_color2;
        p{
            font-weight: 600;
        }
    }
    .fast_contact{

        ul{
            > li{
                padding-left: 28px;
                background-repeat: no-repeat;
                background-position: left center;
                margin-bottom: 10px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 0;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                p,a{
                    color:$font_color2;
                    font-size: 12px;
                    line-height: 17px;
                    font-weight: 600;
                    @include anim;
                    &:hover{
                        color: $accent_color1;
                        text-decoration: none;
                    }
                }
                &.adress{
                    background-image: url("/img/contact_icon1.svg");
                }
                &.phone{
                    background-image: url("/img/contact_icon2.svg");
                }
                &.mail{
                    background-image: url("/img/contact_icon3.svg");
                }
            }
        }
    }
}

.filters{
    letter-spacing: 1px;
    >ul{
        display: inline-block;
        position: relative;
        z-index: 2;
        padding: 16.5px 0px;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            //width: 175px;
            height: 100%;
            background: $white;
            z-index: -1;
            width: 86%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        li{
            text-align: left;
            display: inline-block;
            vertical-align: middle;
            margin-right: 25px;
            @media only screen and (max-width: 991px) {
                margin-right: 0;
                width: 100%;
                text-align: center;
            }
            &:last-child{
                margin-right: 35px;
                width: 200px;
                @media only screen and (max-width: 1200px) {
                    width: 150px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-top: 15px;
                }
            }
        }
    }
    .filter_label{
       font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: $black;
        margin-bottom: 0;
    }
    .chosen-container-single {
        .chosen-single{
            border: none;
            background: transparent;
            box-shadow: none;
            font-family: $main_font;

            span{
                color: $black;
                text-decoration: underline;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
    .chosen-container {
        .chosen-drop{
            background: $theme_color1;
            border: none;
        }
        .chosen-results{
            li{
                width: 100%;
                font-size: 12px;
                text-transform: uppercase;
                color: $black;
                font-weight: 700;
                &.highlighted{
                    color: $white;
                    background: $accent_color1;
                }
            }
        }
    }
    .chosen-container-active{
        &.chosen-with-drop {
            .chosen-single{
                border: none;
                background: $theme_color1;
            }
        }
    }
}

.nav_way{
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    .nav_way_list{
        > ul{
            > li{
                color: #909090;
                display: inline-block;
                vertical-align: middle;
                &:hover{
                    a{
                        text-decoration: none;
                    }
                }
                &:last-child{
                    color:$accent_color1;
                    &:after{
                        background: $accent_color1;
                    }
                    > a{
                        color: inherit;
                    }
                }
                > a{
                    color: inherit;
                }
                &:after{
                    content: '';
                    width: 20px;
                    height: 2px;
                    background: #909090;
                    margin: 0 20px 3px 20px;
                    display: inline-block;
                }
                > a{
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 700;
                }
            }
        }
    }
}

.news_details, .project_details{
    &.pd{
        padding-top: 100px;
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
        }
    }
    .main_image{
        width: 100%;
        height: 0;
        padding-bottom: 44.3%;
        @include bg_pos;
        background-size: cover;
    }
    .main_desc{
        padding: 0 190px;
        @media only screen and (max-width: 1500px) {
            padding: 0;
        }
        .desc_holder{
            margin-top: -140px;
            position: relative;
            z-index: 2;
            padding: 55px 66px;
            background: $white;
            @media only screen and (max-width: 991px) {
                margin-top: 0;
                //padding: 55px 0;
                padding: 55px 0 30px 0;
            }
            .default_font{
                color: $font_color1;
                font-size: 15px;
                h6{
                    font-size: 17px;
                }
            }
            .intro_desc{
                padding-bottom: 45px;
                margin-bottom: 45px;
                border-bottom: 1px solid #ececec;
                .default_font{
                    color: $font_color1;
                }
            }
        }
    }
    .gallery_list{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: calc(100% / 6);
                background: $black;
                position: relative;
                overflow: hidden;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                &:hover{
                    .image{
                        opacity: 0.2;
                        transform: scale(1.5) rotate(-10deg);
                    }
                    .cover{
                        top: 0;
                    }
                    .gal_title{
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                .image{
                    @include bg_pos;
                    background-size: cover;
                    @include anim;
                    width: 100%;
                    height: 0;
                    padding-bottom: 70.36%;
                    opacity: 1;
                    position: relative;
                }
                .gal_title{
                    @include fill;
                    @include vertical;
                    @include anim;
                    z-index: 2;
                    background: rgba(0,0,0,0.3);
                    visibility: visible;
                    opacity: 1;
                    > div{
                        width: 100%;
                        color: $white;
                        text-transform: uppercase;
                        p{
                            display: inline-block;
                            padding: 0px 10px;
                            background: $accent_color1;
                            border-radius: 3px;
                        }
                    }
                }
                .cover{
                    @include anim;
                    @include fill;
                    @include vertical;
                    top: 100%;
                    z-index: 1;
                    > div{
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.download_list{
    margin-bottom: 50px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
    > ul{
        margin: 0 -30px;
        > li{
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding:0 30px;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &:hover{
                a{
                    text-decoration: none;
                }
                .download_box{
                    transform: scale(1.05);
                }
            }
            .download_box{
                padding-left: 20px;
                position: relative;
                @include anim;
                .icon{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 32px;
                    height: 100%;
                    @include bg_pos;
                    background-image: url("/img/file_icon.svg");
                }
                .inner_download_box{
                    background-color: #eaebeb;
                    padding-left: 60px;
                    padding-right: 60px;
                    background-repeat: no-repeat;
                    background-position: right 30px center;
                    background-image: url("/img/download_arr.svg");
                    line-height: 60px;
                    font-size:12px;
                    color: $font_color2;
                    @media only screen and (max-width: 991px) {
                        padding-left: 30px;
                    }
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    strong{
                        color: $font_color1;
                    }
                }
            }
        }
    }
}

.project_details{
    .project_top{
        height: 100vh;
        width: 100%;
        position: relative;
        background: $black;
        overflow: hidden;
        .project_main_image{
            @include fill;
            @include bg_pos;

            background-size: cover;
            opacity: 0.5;
            .cover{
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                background: rgba(0,0,0,0);
                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.8)));
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
            }
        }
        .project_main_image_content{
            width: 100%;
            text-align: center;
            color: $white;
            @include bg_pos;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 25%;
            z-index: 2;
            > div{
                width: 100%;
                text-align: center;
            }
            header{
                margin-bottom: 20px;
            }
            .short_desc{
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 70px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                    font-size: 20px;
                    line-height: 32px;
                    padding: 0 15px;
                }
            }
            .to_bottom{
                img{
                    cursor: pointer;
                }
            }
        }
    }
    .desc_holder{
        margin-bottom: 60px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
}

.time_line{
    padding-top: 80px;
    padding-bottom: 120px;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url("/img/timeline_bg.jpg");
    min-height: 700px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 60px;
        padding-top: 40px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 0;
        padding-top: 30px;
    }
    .section_header{
        margin-bottom: 60px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    .timeline_desc{
        box-shadow: inset 0px 2px 0px 0px rgba(237, 28, 36, 0.004);
        border-top: 2px solid $accent_color1;
        background: $white;
        padding: 60px;
        position: relative;
        margin-bottom: 53px;
        @media only screen and (max-width: 991px) {
            padding: 30px;
            margin-bottom: 30px;
        }
        &:after{
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            @include bg_pos;
            background-image: url("/img/timeline_desc_arr.png");
            content: '';
            height: 13px;
            width: 31px;
        }
    }
    .time_line_line{
        .time_line_start{
            font-size: 18px;
            line-height: 30px;
            color: $font_color1;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            z-index: 2;
            padding-bottom: 30px;
            margin-bottom: 20px;
            &:after{
                z-index: -1;
                content:'';
                position: absolute;
                width: 15px;
                height: 15px;
                border:2px solid #d4d4d4;
                border-radius: 50%;
                background: $accent_color1;
                bottom: -7px;
                left: 0;
                right: 0;
                margin: 0 auto;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
        }
       > ul{
           position: relative;
           &:after{
               content: '';
               height: calc(100% + 20px);
               width: 1px;
               background: #d4d4d4;
               position: absolute;
               top: -20px;
               left: 0;
               right: 0;
               margin: 0 auto;
               @media only screen and (max-width: 991px) {
                   display: none;
               }
           }
            > li{
                width: 100%;
                //margin-top: -244px;
                @media only screen and (max-width: 991px) {
                    margin-top: 0;
                    margin-bottom: 30px;
                    .more_btn4{
                        width: 100%;
                        text-align: center;
                    }
                }
                &:first-child{
                    margin-top: 0;
                }
                .time_line_content{
                    width: 50%;
                    display: flex;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        flex-flow: column;
                    }
                    > div{
                        @media only screen and (max-width: 991px) {
                            align-items: stretch;
                        }
                        &.date_holder{
                            width: 105px;
                            padding-top: 40px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                order: 1;
                                margin-bottom: 30px;
                                padding-top: 0;
                            }
                            .date{
                                border-top: 1px solid #d4d4d4;
                                text-align: center;
                                position: relative;
                                z-index: 2;
                                .default_font{
                                    line-height: 20px;
                                    padding-top: 12px;
                                }
                                &:before{
                                    z-index: -1;
                                    content:'';
                                    position: absolute;
                                    width: 15px;
                                    height: 15px;
                                    border:2px solid #d4d4d4;
                                    border-radius: 50%;
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }
                                &:after{
                                    z-index: -1;
                                    content:'';
                                    position: absolute;
                                    width: 15px;
                                    height: 15px;
                                    border:2px solid #d4d4d4;
                                    border-radius: 50%;
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }
                            }
                        }
                        &.info_holder{
                            width: calc(100% - 105px);
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                order: 2;
                            }
                        }
                    }
                    .image_holder{
                        width: 100%;
                        .image{
                           width: calc(100% + 22px);

                            height: 0;
                            padding-bottom: 48.302%;
                            @include bg_pos;
                            background-size: cover;
                            position: relative;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                            .cover{
                                width: 22px;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                        }
                    }
                    .desc{
                        background: $white;
                        padding: 17px 30px;
                        -webkit-box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
                        -moz-box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
                        box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
                        @media only screen and (max-width: 991px) {
                            padding: 30px;
                        }
                        .default_font{
                            color: $font_color2;
                            strong{
                                color: $font_color1;
                            }
                            h1,h2,h3,h4,h5,h6,p{
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .time_line_visible{
        .time_line_line{
            > ul{
                > li{
                    display:none;
                    &:nth-child(odd){
                        .time_line_content{
                            margin-left: auto;
                            .date_holder{
                                padding-right: 22px;
                                @media only screen and (max-width: 991px) {
                                    padding-right: 0;
                                }
                                .date{
                                    &:before{
                                        background: $accent_color1;
                                        top: -7px;
                                        left: -7px;
                                    }
                                    &:after{
                                        top: -7px;
                                        right: 0;
                                        background: $white;
                                    }
                                }
                            }
                            .image{
                                margin-left: -22px;
                                @media only screen and (max-width: 991px) {
                                    margin-left: 0;
                                }
                                .cover{
                                    left: 0;
                                    background-position: left top;
                                    background-image: url("/img/time_line_cover_right.png");
                                }
                            }
                        }
                    }
                    &:nth-child(even){
                        .time_line_content{
                            .date_holder{
                                padding-left: 22px;
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                }
                                .date{
                                    &:before{
                                        background: $accent_color1;
                                        top: -7px;
                                        right: -7px;
                                    }
                                    &:after{
                                        top: -7px;
                                        left: 0;
                                        background: $white;
                                    }
                                }
                            }
                            .image{
                                margin-right: -22px;
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                }
                                .cover{
                                    right: 0;
                                    background-position: right top;
                                    background-image: url("/img/time_line_cover_left.png");
                                }
                            }
                        }
                    }
                }
            }
        }

        .show_more{
            position: absolute;
            bottom: 0;
            left: 0;
            background: #efefef;
            text-align: center;
            cursor: pointer;
            position: relative;
            z-index: 2;
            margin-top: -107px;
            @include anim;
            @media only screen and (max-width: 991px) {
                margin-top: 0;
            }
            &.time_line_open{
                margin-top: 60px;
                p{
                    background-image: url("/img/minus_icon.png");
                }
                &:after{
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &:after{
                @include anim;
                z-index: -1;
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                bottom: 100%;
                left: 0;
                background: rgba(255,255,255,0);
                background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
                background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
            }
            p{
                @include anim;
                display: inline-block;
                padding-left: 31px;
                background-repeat: no-repeat;
                background-position: left center;
                line-height: 107px;
                font-size: 18px;
                color: $black;
                font-weight: 700;
                background-image: url("/img/plus_icon.png");
                @media only screen and (max-width: 991px) {
                    font-size: 14px;
                    line-height: 60px;
                }
            }
        }
    }
}

.share_banner{
    background: $accent_color1;
    &.question{
        background: $white;
        @media only screen and (max-width: 991px) {
            .banner_content{
                ul{
                    padding-top: 0;
                }
            }

        }
        .banner_content{
            > ul{
                > li{
                    &.banner_left_side{
                        background-image: url("/img/ask_icon.svg");
                        @media only screen and (max-width: 991px) {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
    .banner_content{
        border-bottom: 1px solid #e3e3e3;
        > ul{
            height: 400px;
            width: 100%;
            display: table;
            @media only screen and (max-width: 991px) {
                display: block;
                height: auto;
                padding: 50px 0;
            }
            > li{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                width: 50%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    display: block;
                }
                &.banner_left_side{
                    @include bg_pos;
                    background-position: left center;
                    background-image: url("/img/share_icon.svg");
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        background-position: left top;
                    }
                }
                .contact_options{
                    text-align: center;
                    padding-top: 40px;
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 100px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                margin-right: 0;
                                margin-bottom: 30px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                            @include anim;
                            &:hover{
                                transform: scale(1.1);
                            }
                            .icon{
                                margin-bottom: 20px;
                            }
                            .desc{
                                font-size: 12px;
                                line-height: 24px;
                                color: $font_color2;
                                .default_font{
                                    padding-top: 20px;
                                    color: $font_color1;
                                }
                                text-transform: uppercase;
                                font-weight: 700;
                                p{
                                    font-weight: inherit;
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .section_header{
            margin-bottom: 0;
            text-transform: none;
            text-align: center;
            h2{
                display: inline-block;
                text-align: left;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
            }
        }
        .share_options{
            text-align: center;
            padding-top: 40px;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 100px;
                    cursor: pointer;
                    @media only screen and (max-width: 991px) {
                        margin-right: 15px;
                    }
                    @include anim;

                    &.social {
                        padding-bottom:5px;
                    }

                    > a {
                        padding:0;

                        input {
                            opacity:0;
                            height:5px;
                            cursor: default;
                        }
                    }

                    &:hover{
                        transform: scale(1.1);
                    }
                    .icon{
                        margin-bottom: 20px;
                    }
                    .desc{
                        font-size: 12px;
                        line-height: 24px;
                        color: $white;
                        text-transform: uppercase;
                        font-weight: 700;
                        p{
                            font-weight: inherit;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.see_other{
    padding: 80px 0;
    background:#f7f7f7;
    border-bottom: 1px solid #e3e3e3;
}

section.login{
    padding-bottom: 110px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url("/img/login_flags.png");
    @media only screen and (max-width: 1500px) {
        padding-bottom: 60px;
    }
    .login_form{
        padding: 0 96px;
        @media only screen and (max-width: 1500px) {
            padding: 0 50px;
        }
        @media only screen and (max-width: 1300px) {
            padding: 0;
        }
    }
    .under_login_box{
        font-size: 12px;
        line-height: 18px;
        color: $font_color2;
        @media only screen and (max-width: 991px) {
            text-align: center;
            padding-top: 20px;
            div{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        a{
            color: $font_color2;
            text-decoration: underline;
        }
    }
    .login_header{
        text-align: center;
        font-size: 24px;
        color: $font_color1;
        line-height: 34px;
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 28px;
        }
        div{
            margin-bottom: 30px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 15px;
            }
        }
    }
    .login_box{
        &.login{
            > div{
                &.icon{
                    background-image: url('/img/login_icon.svg');
                    margin-bottom: 30px;
                }
            }
        }
        &.password{
            margin-bottom: 60px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 40px;
            }
            > div{
                &.icon{
                    background-image: url('/img/password_icon.svg');
                }
            }
        }
        > div{
            display: inline-block;
            vertical-align: top;
            height: 60px;
            &.icon{
                width: 60px;
                background-color: #ebebeb;
                @include bg_pos;
                border: 1px solid #ebebeb;
            }
            &.holder{
                position: relative;
                width: calc(100% - 60px);
                border: 1px solid #dfdfdf;
                border-left: 1px solid #ebebeb;
                label{
                    @include fill;
                    width: auto;
                    padding: 0 22px;
                    font-size: 14px;
                    color: $font_color1;
                    line-height: 60px;
                    z-index: 2;
                    font-weight: 400;
                    @include anim;
                    border: 1px solid $white;
                    &.shrink{
                        left: 1px;
                        line-height: 14px;
                        display: inline-block;
                        width: auto;
                        height: auto;
                        background-color: $white;
                        width: calc(100% + 2px);
                        top: -25px;
                        border: 1px solid #dfdfdf;
                        border-bottom: 1px solid $white;
                        padding: 5px 22px
                    }
                }
                input{
                    @include fill;
                    padding: 0 22px;
                    font-size: 14px;
                    color: $font_color1;
                    border: none;
                  //  outline: none;
                }
            }
        }
    }
    .submit_box{
        background: $accent_color1;
        color: $white;
        font-size:12px;
        line-height: 50px;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        margin-bottom: 25px;
        p{
            font-weight: 700;
        }
        input{
            @include fill;
            opacity: 0;
        }
    }
}

.submit_box{
    background: $accent_color1;
    color: $white;
    font-size:12px;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    margin-bottom: 25px;
    &.focused{
        border: 2px solid #23527c;
    }
    &:hover{
        &:after, &:before{
            height: 100%;
            width: 50px;
            opacity: 1;
        }
    }
    &:after, &:before{
        opacity: 0;
    }
    &:before{
        content: '';
        position: absolute;
        left: -15px;
        top: -15px;
        height: 0%;
        width: 0px;
        border-top: 1px solid $accent_color1;
        border-left: 1px solid $accent_color1;
        @include anim;
    }
    &:after{
        content: '';
        position: absolute;
        right: -15px;
        bottom: -15px;
        height: 0%;
        width: 0px;
        border-right: 1px solid $accent_color1;
        border-bottom: 1px solid $accent_color1;
        @include anim;
    }
    &.register{
        width: 225px;
        margin-left: auto;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    p{
        font-weight: 700;
    }
    input{
        @include fill;
        opacity: 0;
    }
}

.more_btn2{
    position: relative;
    .btn_sub{
        opacity: 0;
        @include fill;
    }
}

.default_form{
    padding: 0 57px;
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
    &.contact_form{
        padding: 0;
        .row{
            margin: 0 -10px;
            > div{
                padding: 0 10px;
            }
        }
        .holder{
            margin-bottom: 25px;
        }
    }
    .row{
        margin: 0 -20px;
        > div{
            padding: 0 20px;
        }
    }
    .radio_list{
        padding-top: 10px;

        .field_radio_box {

            .field_radio_input {
                width: 12px;
                display:inline-block;
                vertical-align: top;

                .radio_image_box{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #cccccc;
                    position:relative;
                    overflow: hidden;

                    > div {
                        display:none;
                        background: $accent_color1;
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;

                        &.on {
                            display:block;
                        }
                    }
                }
            }

            .field_radio_subtitle {
                display:inline-block;
                vertical-align: top;

                width: calc(100% - 12px);
                padding-left: 5px;
                color:$black;
                line-height: 12px;
                font-size:14px;
            }
        }

        > ul{
            > li{
                width: 100%;
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.radio_holder{
                        width: 12px;
                        .checkbox_image_box, .radio_image_box{
                            width: 12px;
                            > div{
                                width: 100%;
                                height: 12px;
                                border-radius: 50%;
                                border: 1px solid #cccccc;
                                &.yes{
                                    &.on{
                                        background: $accent_color1;
                                    }
                                }
                            }
                        }
                    }
                    &.radio_desc{
                        width: calc(100% - 12px);
                        padding-left: 15px;
                        color:$black;
                        .default_font{
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
    .default_label{
        display: inline-block;
        width: 100%;
        color: #010101;
        font-size: 16px;
        font-weight: 400;
        sup{
            color: $accent_color1;
        }
    }
    .input_table{
        width: 100%;
        border: 1px solid #dfdfdf;
        margin: 5px 0 30px 0;
        thead{
            tr{
                th{
                    background: #f8f8f8;
                    height: 55px;
                    text-align: center;
                    width: 33.33%;
                    border-right: 1px solid #dfdfdf;
                    border-bottom: 1px solid #dfdfdf;
                    &:last-child{
                        border-right: none;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    height: 55px;
                    position: relative;
                    width: 33.33%;
                    border-right: 1px solid #dfdfdf;
                    &:last-child{
                        border-right: none;
                    }
                    .table_i{
                        @include fill;
                        padding: 0 20px;
                        font-size: 14px;
                        color: $font_color1;
                      //  outline: none;
                        border: none;
                    }
                }
            }
        }
    }
    .icon_select_holder{
        .chosen-container-single{
            text-align: left;
            .chosen-single{
                background-image: url("/img/cal_icon.png");
                background-repeat: no-repeat;
                background-position: left 28px center;
                padding: 0 70px 0 63px;
            }
        }
    }
    .chosen-container-single {
        .chosen-single{
            border: 1px solid #dfdfdf;
            background: #f8f8f8;
            box-shadow: none;
            font-family: $main_font;
            height: 55px;
            padding: 0 70px 0 30px;
            div{
                width: 70px;
                font-weight: 100%;
                top: 0;
                height: 100%;
                b{
                    @include bg_pos;
                    background-image: url("/img/select_arr.png");
                }
            }
            span{
                color: $font_color2;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 55px;
            }
        }
    }
    .chosen-container {
        .chosen-drop{
            background: $theme_color1;
            border: none;
            box-shadow: none;
        }
        .chosen-results{
            li{
                width: 100%;
                font-size: 14px;
                text-transform: uppercase;
                color: $black;
                font-weight: 400;
                padding: 10px 26px;
                &.highlighted{
                    color: $white;
                    background: $accent_color1;
                }

                &:hover {
                    color: $white;
                    background: $accent_color1;
                }
            }
        }
    }
    .chosen-container-active{
        &.chosen-with-drop {
            .chosen-single{
                border: none;
                background: $theme_color1;
            }
        }
    }
    .holder{
        margin-bottom: 35px;
    }

    .form_err {
        color:$accent_color1;
    }

    input{
      //  outline: none;
        &.default_input{
          width: 100%;
            background: $white;
            border: 1px solid #dfdfdf;
            height: 55px;
            padding: 0 22px;
            color: #010101;
        }
    }
    .default_textarea{
        width: 100%;
        background: $white;
        border: 1px solid #dfdfdf;
        min-height: 190px;
        padding: 22px;
        color: #010101;
       // outline: none;
        resize: none;
    }
    .rules{
        ul{
            li{
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.check_holder{
                        width: 20px;
                    }
                    &.check_desc{
                        width: calc(100% - 20px);
                        padding-left: 20px;
                        font-size: 12px;
                        line-height: 18px;
                        color:$font_color2;
                        a{
                            color: $accent_color1;
                        }
                    }
                }
            }
        }
    }

    p.err {
        color: $accent_color1;
    }
}

.icon_btn{
    display: inline-block;
    background: $black;
    color: $white;
    padding: 0 40px;
    text-transform: uppercase;
    font-size: 12px;
    @include anim;
    @media only screen and (max-width: 1200px) {
        padding: 0 30px;
    }
    &:hover{
        color: $accent_color1;
        text-decoration: none;
    }
    &.photo_btn{
        span{
            background-image: url("/img/img_icon.svg");
        }
    }
    &.movie_btn{
        span{
            background-image: url("/img/movie_icon.svg");
        }
    }
    span{
        display: block;
        padding: 18px 0 18px 40px;
        background-repeat: no-repeat;
        background-position: left center;
        strong {
            padding-right: 44px;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url("/img/more_arr2.svg");
        }
    }
}

.main_projects{
    padding-bottom: 60px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 20px;
    }
    .projects_btns{
        @media only screen and (max-width: 991px) {
            margin-top: 15px;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 60px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 30px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                    text-align: right;
                    .icon_btn{
                        width: 100%;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .projects_list{
        > ul{
            margin: 0 -30px;
            padding-top: 10%;
            @media only screen and (max-width: 991px) {
                padding-top: 0;
            }
            > li{
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 60px;
                width: 50%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }
                &:nth-child(odd){
                    margin-top: -10%;
                    @media only screen and (max-width: 991px) {
                        margin-top: 0;
                    }
                }
                a{
                    &.focused{
                        border: 2px solid #23527c;
                    }
                }
                article{
                    background: $theme_color1;
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 66.442%;
                    //transform-style: preserve-3d;
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 84%;
                    }
                    .image{
                        @include bg_pos;
                        background-size: cover;
                        @include fill;
                        .cover{
                            @include fill;
                            background: rgba(0,0,0,0);
                            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.8)));
                            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                            background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                            background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
                        }
                    }
                    .desc{
                        z-index: 2;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        padding: 0 80px 40px 80px;
                        color: $white;

                        transform: translateZ(20px);
                        @media only screen and (max-width: 991px) {
                            padding: 0 15px 15px 15px;
                        }
                        h2{
                            margin-bottom: 40px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 0;
                            }
                        }
                        p{
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $accent_color1;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.main_proposal{
    padding-bottom: 90px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 60px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .section_header{
        margin-bottom: 60px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        img{
            margin-top: -50px;
            @media only screen and (max-width: 991px) {
                margin-top: 30px;
            }
        }
    }
    .desc_box{
        >div.row{
            margin: 0 -50px;
            > div{
                padding: 0 50px;
                @media only screen and (max-width: 991px) {
                    > .default_font{
                        margin-bottom: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.about, .main_proposal{
    .desc_box{
        .section_header{
            text-transform: none;
        }
        .top_about_list{
            > ul{
                > li{
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: middle;
                        &.icon{
                            @include bg_pos;
                            background-size: cover;
                            width: 95px;
                            height: 0;
                            padding-bottom: 95px;
                            @media only screen and (max-width: 991px) {
                                display: block;
                                margin: 0 auto 30px auto;
                            }
                        }
                        &.desc{
                            width: calc(100% - 95px);
                            padding-left: 40px;
                            color: $font_color1;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                                padding-left: 0;
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .about_top{
        margin-bottom: 100px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        .row{
            margin: 0 -50px;
            > div{
                padding: 0 50px;
            }
        }

        .image{
            width: 100%;
            height: 0;
            padding-bottom: 78.05%;
            @include bg_pos;
            background-size: cover;
        }
    }
    .text_boxes{
        margin-bottom: 105px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        .poland_box{
            margin: 0 auto 0px auto;
            width: 160px;
            height: 200px;
            @include bg_pos;
            @include vertical;
            background-image: url("/img/poland.svg");
            position: relative;
            z-index: 2;
            > div{
                width: 100%;
                text-align: center;
                color: $black;
            }
        }
        .text_box_list{
            margin-top: -45px;
            padding-top: 150px;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 75% auto;
            background-image: url("/img/circle_bg.png");
            @media only screen and (max-width: 1500px) {
                padding-top: 90px;
            }
            > ul{
                margin: 0 -15px;
                > li{
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 15px;
                    width: 33.33%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    .default_font{
                        position: relative;
                        padding-top: 35px;
                        color: $font_color1;
                        &:after{
                            content: '';
                            width: 64px;
                            height: 2px;
                            background: $accent_color1;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

.main_members{
    position: relative;
    z-index: 2;
    margin-bottom: 100px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    &:after{
        z-index: -1;
        content: '';
        height: 116%;
        width: 100%;
        @include bg_pos;
        background-size: cover;
        position: absolute;
        top: 44%;
        left: 0;
        background-image: url('/img/red_block_bg.jpg');
    }
    .management{
        -webkit-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
        -moz-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
        box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
        padding: 70px 200px 0px 200px;
        background: $white;
        border-top: 2px solid $accent_color1;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
        background-image: url("/img/members_bg.jpg");
        @media only screen and (max-width: 991px) {
            padding: 30px 15px;
        }
        .arr{
            position: absolute;
            top: 0;
            height: 100%;
            @include vertical;
            padding-top: 115px;
            @media only screen and (max-width: 991px) {
                position: relative;
                height: auto;
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                display: none;
            }
            img{
                cursor: pointer;
            }
            &.arr_left{
                left: 80px;
                @media only screen and (max-width: 991px) {
                    left: auto;
                    text-align: left;
                }
            }
            &.arr_right{
                right: 80px;
                @media only screen and (max-width: 991px) {
                    right: auto;
                    text-align: right;
                }
            }
        }
        .members{
            text-align: center;
            margin-bottom: 30px;
            > ul{
                @media only screen and (max-width: 1200px) {
                    margin: 0 -10px;
                }
                > li{
                    display: inline-block;
                    vertical-align: top;

                    cursor: pointer;
                    margin-right: 60px;
                    width: 186px;
                    &:last-child{
                        margin-right: 0;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 0;
                        display: inline-block;
                        vertical-align: top;
                        padding: 0 10px;
                        width: 33.33%;
                    }
                    &.on{
                        .image{
                            border: 2px solid $accent_color1;
                        }
                    }
                    div.image{
                        @include anim;
                        border: 2px solid transparent;
                        @include bg_pos;
                        background-size: cover;
                        border-radius: 50%;
                        margin: 0 auto 15px auto;
                        width: 85px;
                        height: 85px;
                        background-color: $theme_color1;
                        @media only screen and (max-width: 1200px) {
                            width: 100%;
                            height: 0;
                            padding-bottom: 100%;
                        }
                        @media only screen and (max-width: 991px) {
                            padding-bottom: 95%;
                        }
                    }
                    .desc{
                        color: $font_color2;
                        font-size: 16px;
                        line-height: 23px;
                        text-align: justify;
                        text-align-last: center;
                        @media only screen and (max-width: 1200px) {
                            display: none;
                        }
                        .name{
                            color: $font_color1;
                        }
                        .role{
                            color: $font_color2;
                        }
                    }
                }
            }
        }
        .members_content{
            height: 430px;
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: 991px) {
                height: 300px;
            }
            > ul{
                @include fill;
                > li{
                    @include fill;
                    .about_member{
                        @include fill;
                        display: flex;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        > div{
                            height: 100%;
                            &.desc{
                                //margin-top: 50px;
                                width: 64%;
                                overflow-y: scroll;
                                .default_font{
                                    padding-bottom: 50px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    width: 100%;
                                    margin-top: 0;
                                }
                                /* width */
                                &::-webkit-scrollbar {
                                    width: 5px;
                                }
                                /* Track */
                                &::-webkit-scrollbar-track {
                                 //   background: $white;
                                }

                                /* Handle */
                                &::-webkit-scrollbar-thumb {
                                    background: $accent_color1;

                                }
                                /* Handle on hover */
                                &::-webkit-scrollbar-track:hover {
                                    @include anim;
                                    background: $theme_color1;
                                }
                                > div{
                                    width: 100%;
                                }
                            }
                            &.image{
                                width: 36%;
                                background-repeat: no-repeat;
                                background-position: right bottom;
                                background-size: contain;
                                @media only screen and (max-width: 1200px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .member_header{
                margin-bottom: 30px;
                font-size: 12px;
                color: $font_color2;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 15px;
                }
                .default_font{
                    color: $accent_color1;
                }
            }
            .short_desc{
                .default_font{
                    color: $font_color1;
                }
            }
        }
    }
}

.movies{
    position: relative;
    width: 100%;
    z-index: 3;
    margin-bottom: 120px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 60px;
    }
    .grid_movies{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(27%, 1fr));
        grid-gap: 60px;
        grid-auto-rows: minmax(31.3vh, auto);
        grid-auto-flow: dense;
        @media only screen and (max-width: 1500px) {
            grid-gap: 30px;
        }
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            grid-auto-rows: minmax(70vh, auto);
        }
        > div{
            background: $black;
            position: relative;
            .movie_box{
              @include fill;
                @include vertical;
                color: $white;
                cursor: pointer;
                position: relative;
                &:hover{
                    .movie_img{
                        opacity: 1;
                        video {
                            position:absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height:100%;
                        }
                    }
                }
                .movie_img{
                    @include fill;
                    //@include bg_pos;
                    background-size: cover;
                    @include anim;
                    opacity: 0.7;
                    background-color:#000;

                    iframe, video {
                        position:absolute;
                        top:0!important;
                        left:0!important;
                        width:100%!important;
                        height:100%!important;
                    }

                    .video_parent_box {
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }

                    .video_play {
                        display:none!important;
                    }
                }
                > div.movie_box_content{
                    margin-top: 50px;
                    width: 100%;
                    text-align: center;
                    z-index: 2;
                    position: relative;
                }
            }
            &.small{
                grid-column-end: span 1;
                grid-row-end: span 1;
                font-size: 18px;
                img{
                    margin-bottom: 30px;
                }
            }
            &.large{
                grid-column-end: span 2;
                grid-row-end: span 2;
                @media only screen and (max-width: 991px) {
                    grid-column-end: span 1;
                    grid-row-end: span 1;
                }
                img{
                    margin-bottom: 70px;
                }
            }
        }
    }
}

.main_social{
    background: $theme_color1;
    padding: 100px 0 120px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 60px 0;
    }
    .section_header{
        .default_font{
            h2{
                margin-bottom: 10px;
            }
        }
    }
    .grid_social{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        grid-gap: 50px;
        grid-auto-rows: minmax(33.6vh, auto);
        grid-auto-flow: dense;
        @media only screen and (max-width: 1500px) {
            grid-gap: 20px;
            grid-auto-rows: minmax(43.6vh, auto);
        }
        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(auto-fill, minmax(36%, 1fr));
            grid-auto-rows: minmax(40.6vh, auto);
        }
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        > div{
            background: $black;
            &.small{
                grid-column-end: span 1;
                grid-row-end: span 1;
                .social_image{
                    height: calc(100% - (56px + 60px));
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                    }
                }
                .social_desc{
                    height: auto;
                }
            }
            &.large{
                grid-column-end: span 2;
                grid-row-end: span 2;
                @media only screen and (max-width: 991px) {
                    grid-column-end: span 1;
                    grid-row-end: span 2;
                }
                .social_image{
                    background-position: center top;
                    height: calc(100% - (56px + 135px));
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                    }
                }
                .social_desc_top{
                    height: calc(100% - 50px);
                    font-size: 12.5px;
                    line-height: 24px;
                    color: $font_color1;
                    padding-top: 20px;
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    a{
                        color: #365196;
                    }
                }
            }
        }
    }
    a{
        &:hover{
            text-decoration: none;
        }
    }
    .social_box{
        height: 100%;
        .social_type{
            height: 56px;
            background: $white;
            padding:6px 10px 0 10px;
            .social_acc{
                width: 100%;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    &.logo{
                        width: 44px;
                        height: 44px;
                        border: 1px solid #f0f0f0;
                        border-radius: 50%;
                        @include bg_pos;
                        background-size: cover;
                    }
                    &.title{
                        width: calc(100% - 44px);
                        padding-left: 11px;
                        font-weight: 700;
                        font-size: 12.5px;
                        color: $font_color1;
                        p{
                            font-weight: inherit;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            .social_media_logo{
                width: 44px;
                height: 44px;
                @include bg_pos;
                margin-left: auto;
            }
        }
        .social_image{
            width: 100%;
            @include bg_pos;
            background-size: cover;
        }
        .social_desc{
            background: $white;
            height: 135px;
            padding: 0 10px;
            @media only screen and (max-width: 991px) {
             height: auto;
            }
            .social_desc_bottom{

               // @include vertical;
                @media only screen and (max-width: 991px) {
                    height: auto;
                }
                > div{
                    width: 100%;
                    color: $font_color2;
                    font-size: 11.5px;
                    .desc_top, .desc_bottom{
                        @include vertical;
                        > div{
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            width: 100%;
                        }
                    }
                    .desc_top{
                        height: 30px;
                    }
                    .share{
                        height: 30px;
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: top;
                                @media only screen and (max-width: 1500px) {
                                    margin-right: 5px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                                a{
                                    color: $font_color2;
                                }
                                &:last-child{
                                    &:after{
                                        display: none;
                                    }
                                }
                                &:after{
                                    content: '•';
                                    margin: 0 10px;
                                    @media only screen and (max-width: 1500px) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    a{
                        color: $font_color2;
                    }
                }
            }
        }
    }
}

.contact{
    padding-bottom: 120px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 60px;
    }
    .container{
        > .row{
            margin: 0 -30px;
            > div{
                padding: 0 30px;
            }
        }
    }
    .contact_data_side{
        @media only screen and (max-width: 991px) {
            margin-bottom: 50px;
        }
        .contact_data{
            -webkit-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
            -moz-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
            box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
            padding: 40px 50px 10px 50px;
            background: $white;
            border-top: 2px solid $accent_color1;
            @media only screen and (max-width: 991px) {
                padding: 40px 30px 10px 30px;
            }
            .contact_data_box{
                margin-bottom: 29px;
                header{
                    font-size: 18px;
                    line-height: 30px;
                    color: $font_color1;
                    margin-bottom: 25px;
                    text-transform: uppercase;
                }
                ul{
                    li{
                        margin-bottom: 20px;
                        padding-bottom: 5px;
                        padding-left: 37px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        &.adress{
                            background-image: url("/img/contact_icon4.svg");
                        }
                        &.phone{
                            background-image: url("/img/contact_icon5.svg");
                        }
                        &.mail{
                            background-image: url("/img/contact_icon6.svg");
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .default_font{
                            line-height: 18px;
                            a{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .go_to_proposal{
            @include bg_pos;
            background-size: cover;
            height: 223px;
            width: 100%;
            position: relative;
            background-image: url("/img/go_to_proposal_bg.jpg");
            .go_proposal_content{
                @include fill;
                z-index: 2;
                @include vertical;
                div{
                    width: 100%;
                    padding: 0 55px;
                    color: $white;
                    font-size: 18px;
                    line-height: 30px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 30px;
                        text-align: center;
                    }
                    .more_btn2{
                        margin-top: 30px;
                    }
                }
            }
            .cover{
                @include fill;
                background-image: url("/img/go_to_proposal_bg_cover.png");
            }
        }
    }
    .contact_form{
        .section_header{
            margin-bottom: 40px;
        }
        .submit_box{
            margin-bottom: 0;
        }
    }
}

section.map{
    height: 0;
    padding-bottom: 27.1%;
    width: 100%;
    position: relative;
    iframe{
        @include fill;
        border: none;
    }
}

.get_proposal{
    background-color: $accent_color1;
    .get_proposal_content{
        height: 617px;
        width: 100%;
        display: table;
        @include bg_pos;
        background-size: contain;
        background-image: url("/img/dott_map.png");
        @media only screen and (max-width: 1500px) {
            height: 517px;
        }
        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
            text-align: center;
            padding: 50px 0;
        }
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            width: 50%;
            @media only screen and (max-width: 991px) {
                display: block;
                width: 100%;
                margin-bottom: 30px;
            }
            &.get_proposal_left{
                .section_header{
                    margin-bottom: 60px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                    }
                }
                .desc{
                    color: $white;
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 60px;
                }
            }
            &.get_proposal_right{
                padding-left: 11.6%;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                }
              .go_to_login_box{
                padding: 55px;
                background: $white;
                  @media only screen and (max-width: 991px) {
                      padding: 30px;
                  }
                  header{
                      position: relative;
                      margin-bottom: 40px;
                      z-index: 2;
                      @media only screen and (max-width: 991px) {
                          margin-bottom: 20px;
                      }
                      &:after{
                          z-index: -1;
                          content: '';
                          position: absolute;
                          top: 50%;
                          border-top: 2px solid $accent_color1;
                          width: 100%;
                          left: 0;
                      }
                      .default_font{
                          color: $font_color1;
                          display: inline-block;
                          padding-right: 25px;
                          background: $white;
                          @media only screen and (max-width: 991px) {
                              padding-left: 25px;
                          }
                      }
                  }
                  .login_desc{
                      color: $font_color2;
                      margin-bottom: 40px;
                  }
              }
            }
        }
    }
}

.dashboard{
    .row{
        &.more_pd{
            margin: 0 -60px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -30px;
            }
            > div{
                padding: 0 60px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 30px;
                }
            }
        }
    }
    .dashboard_btn{
        display: inline-block;
        font-size: 12px;
        line-height: 44px;
        padding: 0 40px;
        color:$font_color1;
        @include anim;
        background: #e4e4e4;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        &.large{
            width: 100%;
            text-align: center;
            background: $accent_color1;
            color: $white;
        }
        &:hover{
            text-decoration: none;
            background: $accent_color1;
            color: $white;
        }
    }
    &.dashboard_home{
        padding-bottom: 120px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 60px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        .section_header{
            text-transform: none;
        }
    }
    .proposal_heeader{
        margin-bottom: 30px;
        color: $font_color1;
        @media only screen and (max-width: 991px) {
            text-align: center;
            h2{
                margin-bottom: 15px;
            }
        }
    }
    .your_data{
        .your_data_box{
            background: #f6f6f6;
            padding: 40px;
            margin-bottom: 40px;
            @media only screen and (max-width: 1300px) {
                padding: 30px;
            }
            @media only screen and (max-width: 991px) {
                margin-top: 50px;
            }
        }
    }
    .your_proposals{
        .proposal_list{
            > ul{
                > li{
                    margin-bottom: 21px;
                    @include anim;
                    &:hover{
                        a{
                            text-decoration: none;
                        }
                        transform: scale(1.05);
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    article{
                        border-top: 2px solid #8c8c8c;
                        background: $white;
                        padding:33px 40px;
                        position:relative;
                        -webkit-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
                        -moz-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
                        box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
                        @media only screen and (max-width: 991px) {
                            text-align: center;
                        }
                        .pdf_download {
                            display:block;
                            position:absolute;
                            right:20px;
                            top:20px;
                            border: 1px solid #e8e8e8;
                            color:#000;
                            font-size:12px;
                            padding: 0 10px;
                            @media only screen and (max-width: 991px) {
                                top: 0;
                                left: 0;
                                width: 100%;
                                position: relative;
                                margin-bottom: 20px;
                            }
                            svg {
                                position:relative;
                                top: 7px;
                                left: -4px;
                            }
                        }

                        > div{
                            display: inline-block;
                            vertical-align: bottom;
                            &.proposal_left{
                                width: calc(100% - 114px);
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                            &.proposal_right{
                                width: 114px;
                                border-left: 1px solid #e8e8e8;
                                position:relative;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    border-left: none;
                                    .more_btn2{
                                        padding-left: 0;
                                    }
                                }
                                .more_btn2{
                                    padding-right: 0;
                                }
                            }
                        }
                        &.accepted{
                            border-top: 2px solid #4ba423;
                            .status{
                                .status_info{
                                    p{
                                        color: #4ba423;
                                        &:before{
                                            content: '';
                                            display: inline-block;
                                            vertical-align: middle;
                                            width: 8px;
                                            height: 8px;
                                            background: #4ba423;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                            }
                        }
                        &.no_accepted{
                            border-top: 2px solid $accent_color1;
                            .status{
                                .status_info{
                                    p{
                                        color: $accent_color1;
                                        &:before{
                                            content: '';
                                            display: inline-block;
                                            vertical-align: middle;
                                            width: 8px;
                                            height: 8px;
                                            background: $accent_color1;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                            }
                        }
                        &.sended{
                            border-top: 2px solid #407fff;
                            .status{
                                .status_info{
                                    p{
                                        color: #407fff;
                                        &:before{
                                            content: '';
                                            display: inline-block;
                                            vertical-align: middle;
                                            width: 8px;
                                            height: 8px;
                                            background: #407fff;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                            }
                        }
                        header{
                            h3{
                                font-size: 18px;
                                line-height: 30px;
                            }
                            color: $font_color1;
                            margin-bottom: 15px;
                        }
                        .date{
                            font-size: 12px;
                            line-height: 18px;
                            color: $font_color2;
                            margin-bottom: 30px;
                        }
                        .status{
                            color: $font_color2;
                            @media only screen and (max-width: 991px) {
                                p,div{
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                            .default_font{
                                line-height: 20px;
                                p{
                                    margin: 0;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 15px;
                                    }
                                }
                            }
                            .status_info{
                                p{
                                    &:before{
                                        content: '';
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 8px;
                                        height: 8px;
                                        background: #8c8c8c;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .data_holders{
        margin-bottom: 35px;
        ul{
            li{
                height: 70px;
                width: 100%;
                @include vertical;
                margin-bottom: 5px;
                background: $white;
                > div{
                    width: 100%;
                    font-size:12px;
                    line-height: 18px;
                    padding: 0 30px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 15px;
                    }
                    .default_font{
                        color: $font_color1;
                    }
                }
            }
        }
    }
}

.proposal_form_steps{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    ol {
        padding-left: 0;
        counter-reset: item;
    }
    li{
        display: block;
        height: 48px;
        line-height: 48px;
        padding-left: 75px;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        @media only screen and (max-width: 991px) {
            padding-left: 40px;
            display: inline-block;
            vertical-align: top;
            margin: 0 3px 15px 3px;
            height: 40px;
        }

        &.has_errors .default_font p {
            color:$accent_color1!important;
        }

        &.on{
            &:before{
                border: 2px solid $accent_color1;
                background: $accent_color1;
                color: $white;
            }
            .default_font{
                color: $font_color1;
                font-weight: 700;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        .default_font{
            line-height: 50px;
            color: $font_color2;
            font-weight: 400;
            @media only screen and (max-width: 991px) {
                display: none;
            }
            p{
                font-weight: inherit;
            }
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 48px;
            line-height: 44px;
            border: 2px solid #cfcfcf;
            border-radius: 50%;
            content: counters(item, "");
            counter-increment: item;
            font-weight: 500;
            display: block;
            color: $font_color2;
            font-weight: 700;
            font-size:18px;
            text-align: center;
            @media only screen and (max-width: 991px) {
                width: 40px;
                line-height: 36px;
            }
        }
    }
}

.proposal_form {
    .default_form {
        .holder{
            @media only screen and (max-width: 991px) {
                margin-bottom: 10px;
            }
        }
    }
    padding-bottom:50px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .step {
        margin-bottom: 35px;
        color: $font_color2;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        strong {
            color: $font_color1;
        }
    }

    .end_step {
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #e4e4e4;
    }

    .form_btns{
        text-align: right;
        margin-top: -10px;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 40px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 30px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    .steps {
        .step_box {
            display:none;
        }
    }
}

.proposal_form_content{
    padding: 55px 60px;
    background: $white;
    border-top: 2px solid $accent_color1;
    -webkit-box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
    -moz-box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
    box-shadow: 0px 0px 18px 3px rgba(212,212,212,0.6);
    margin-bottom: 120px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 60px;
    }
    @media only screen and (max-width: 1200px) {
        padding: 30px 30px 0px 30px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom:30px;
        padding: 30px 15px 0 15px;
    }
    .proposal_form_header{
        margin-bottom: 40px;
        &.main_poposal_form_header{
            text-align: center;
            color: #ed1c24;
        }
        &.main_poposal_form_header2{
            text-align: center;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
    .desc{
        .desc_inner_list{
            .default_font{
                > ul{
                    padding-left: 50px;
                }
            }
            s{
                position: relative;
                display: inline-block;
                text-decoration: none;
                &:after{
                    position: absolute;
                    top: 0;
                    left: -15px;
                    content: '§';
                    color: $accent_color1;
                    font-weight: 700;
                    display: inline-block;
                }
            }
           > ol {
                color: $font_color1;
                padding-left: 0;
                counter-reset: item;
               > li{
                   display: block;
                   padding-left: 14px;
                   position: relative;
                   font-size: 18px;
                   margin-bottom: 44px;
                   &:last-child{
                       margin-bottom: 0;
                   }
                   &:before {
                       content: counters(item, "");
                       counter-increment: item;
                       color: $font_color1;
                       font-weight: 700;
                       display: inline-block;
                       vertical-align: top;
                       padding-left: 15px;
                       @media only screen and (max-width: 991px) {
                           width: 20px;
                       }
                   }
                   > .default_font{
                       padding-left: 25px;
                       @media only screen and (max-width: 991px) {
                           padding-left: 20px;
                       }
                   }
                    > p{
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% - 20px);
                        padding-left: 15px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                        }

                    }
                   &:after{
                       position: absolute;
                       top: 0;
                       left: 0;
                       right: 0;
                       content: '§';
                       color: $accent_color1;
                       font-weight: 700;
                   }
               }
            }

            header{
                color: $font_color1;
                font-weight: 700;
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 25px);
                margin-bottom: 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
                p{
                    font-weight: inherit;
                }
            }
        }
    }
    .default_form{
        padding: 0;
        .info{
            padding-top: 5px;
            font-size: 12px;
            line-height: 18px;
            color: $font_color2;
        }
    }
    .summary_box{
        text-align: center;
        .summary_header{
            margin-bottom: 10px;
            background-repeat: no-repeat;
            background-position: center top;
            background-image: url("/img/green_check.png");
            padding-top: 140px;
        }
        .summary_desc{
            margin-bottom: 50px;
            font-size: 18px;
            line-height: 30px;
            color: $font_color2;

        }
        .btns{
            margin-bottom: 50px;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 30px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .rules{
            margin-bottom: 50px;
            ul{
                li{
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.go_to_news{
    padding-top: 120px;
    @media only screen and (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}

.error_page{
    margin-top: 105px;
    min-height: 88vh;
    @include bg_pos;
    background-position: center top;
    background-image: url("/img/error_page_bg.jpg");
    background-size: cover;
    padding-top: 53px;
    text-align: center;
    color: $font_color1;
    .err_header{
        padding-top: 100px;
        @include bg_pos;
        background-position: center top;
        background-image: url("/img/error_icon.png");
        margin-bottom: 77px;
        h1{
            font-size: 120px;
            line-height: 132px;
            margin-bottom: 50px;
        }
        font-size: 48px;
        line-height: 60px;
    }
    .err_desc{
        .default_font{
            margin-bottom: 70px;
        }
    }
}

.page{
    overflow: hidden;
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .text-right, .text-left{
        text-align: center;
    }
}

.grid_item{
    position: relative;
    transform-style: preserve-3d;
    .desc{
        > div{
            transform: translateZ(50px);
        }
    }
    article{
        transform: translateZ(30px);
        transform-style: preserve-3d;
    }
    &:hover{

        z-index: 2;
        transform: scale(0.95);
        -webkit-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
        -moz-box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
        box-shadow:  0px 0px 40px 0px rgba(219,218,218,0.61);
       .borderr{
           &:after, &:before{
               height: calc(100% + 10px);
               width: calc(100% + 10px);
               opacity: 1;

           }
       }
    }
    .borderr{
        transform: translateZ(50px);
        @include fill;
        &:after, &:before{
            opacity: 0;
            z-index: -1;
        }
        &:before{
            content: '';
            position: absolute;
            left: -5px;
            top: -5px;
            height: 0%;
            width: 0px;
            border-top: 1px solid #cecece;
            border-left: 1px solid #cecece;
            @include anim;
        }
        &:after{
            content: '';
            position: absolute;
            right: -5px;
            bottom: -5px;
            height: 0%;
            width: 0px;
            border-right: 1px solid #cecece;
            border-bottom: 1px solid #cecece;
            @include anim;
        }
        &.register{
            width: 225px;
            margin-left: auto;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }
}

.projects_list{
    .grid_item{
        display: block;
    }
}

.myform2_jslist{
    ul{
        width: 100%;
        border: 1px solid #dfdfdf;
        margin: 5px 0 30px 0;
        display: table;
        border-bottom: none;
        border-collapse: collapse;
        .thead, .trow{
           div{
               &:last-child{
                   width: 50px;
               }
           }
        }
        .thead{
            display: table-row;
            div{
                background: #f8f8f8;
                height: 55px;
                text-align: center;
                border-right: 1px solid #dfdfdf;
                border-bottom: 1px solid #dfdfdf;
                display: table-cell;
                vertical-align: middle;
                &:last-child{
                    border-right: none;
                }
            }
        }
        .trow{
            display: table-row;
            border-bottom: 1px solid #dfdfdf;
            div{
                height: 50px;
                position: relative;
                border-right: 1px solid #dfdfdf;
                display: table-cell;
                vertical-align: middle;
                &:last-child{
                    border-right: none;
                }
                input{
                    @include fill;
                    padding: 0 20px;
                    font-size: 14px;
                    color: $font_color1;
                   // outline: none;
                    border: none;
                }
            }
        }
        a.delete_row{
            background-color: $accent_color1;
            cursor: pointer;
            @include bg_pos;
            background-image: url('/img/table_x.svg');
            background-size: 30px;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.field_checkbox_box{
    margin-bottom: 30px;
     > div{
         display: inline-block;
         vertical-align: top;
         &.field_checkbox_input{
             width: 20px;
             padding-top: 5px;
         }
         &.field_checkbox_subtitle{
             width: calc(100% - 20px);
             padding-left: 20px;
             font-size: 12px;
             line-height: 18px;
             color:$font_color2;
             text-align: left;
             a{
                 color: $accent_color1;
             }
         }
     }
}

.field_radio_input{
    cursor: pointer;
}

.search_popup{
    background: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: none;
    @media only screen and (max-width: 991px) {
        .container{
            width: 100%;
        }
    }
    .popup_holder{
        @include fill;
        @include vertical;
    }
    .popup_title{
        background: $black;
        padding: 30px;
        font-size: 36px;
        line-height: 36px;
        color: $white;
        text-transform: uppercase;
        @media only screen and (max-width: 991px) {
            padding: 15px;
            p{
                text-align: left;
            }
        }
        .close_btn{
            cursor: pointer;
            margin-left: auto;
            width: 36px;
            height: 36px;
            background-color:$accent_color1;
            @include bg_pos;
            background-image: url("/img/table_x.svg");
            position: relative;
            @media only screen and (max-width: 991px) {
                margin-top: -36px;
            }
            &:hover{
                &:after, &:before{
                    height: 100%;
                    width: 36px;
                    opacity: 1;
                }
            }
            &:after, &:before{
                opacity: 0;
            }
            &:before{
                content: '';
                position: absolute;
                left: -15px;
                top: -15px;
                height: 0%;
                width: 0px;
                border-top: 1px solid $accent_color1;
                border-left: 1px solid $accent_color1;
                @include anim;
            }
            &:after{
                content: '';
                position: absolute;
                right: -15px;
                bottom: -15px;
                height: 0%;
                width: 0px;
                border-right: 1px solid $accent_color1;
                border-bottom: 1px solid $accent_color1;
                @include anim;
            }
        }
    }
    .popup_content{
        background: $white;
        padding: 30px;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        .default_form{
            padding: 0;
            .default_input{
                font-size: 18px;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
            }
            .submit_box{
                font-size: 18px;
                margin-bottom: 0;
                line-height: 55px;
                width: 100%;
            }
        }
    }
}

.ajax_field {
    .chosen-container-single-nosearch {
        display: none;
    }

    .chosen-container-single {
        width:100%;
    }
}

.expense_table_container {
    width:100%;

    h4 {
        font-size:20px;
        padding-bottom:20px;
    }

    table {
        width:100%;
        margin-bottom:40px;
    }

    .table_head_tr {

        &.small {
            th {
                font-size:14px;
                line-height:20px;
            }
        }

        th {
            background-color:#f8f8f8;
            height:55px;
            border:1px solid #dfdfdf;
            text-align: center;
            font-weight: normal;
            padding:5px;
        }
    }

    .table_subhead_tr {
        th {
            padding-top:20px;
        }
    }

    .table_body_tr {

        td:first-child {
            min-width: 38px;
        }
        td {
            height: 50px;
            position: relative;
            border: 1px solid #dfdfdf;
            display: table-cell;
            vertical-align: middle;
            padding:5px;

            &.lp {
                text-align: center;
            }

            input[type="text"] {
                width:100%;
                padding:0 1px;
                border:0;
            }

            &.del {
                padding:0;
                width:50px;
                a {
                    height:50px!important;
                    background-color: #ed1c24;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url('/img/table_x.svg');
                    background-size: 30px;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .table_sum_tr {
        td {
            border:1px solid #fff;
            border-bottom: 1px solid #dfdfdf;
            border-top: 1px solid #dfdfdf;
            height:50px;

            &:last-child {
                border-right: 1px solid #dfdfdf;
            }

            &:first-child {
                border-left: 1px solid #dfdfdf;
            }

            &.info_box {
                font-weight: bold;
                padding-right:15px;
            }

            &.number_box {
                padding:0 15px;
            }

            &.add_box {
                padding: 0;
                width: 50px;

                a {
                    height: 50px !important;
                    background-color: #88d40d;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url('/img/table_plus.svg');
                    background-size: 20px;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}

.search_form {

    h3 {
        padding-bottom: 20px !important;
    }

    .search_form_box {
        width: 100%;

        > div {
            display: inline-block;
            vertical-align: top;
        }

        .serach_form_input {
            width: calc(100% - 200px);
            margin-right: -4px;

            input {
                width: 100%;
                height: 50px;
            }
        }

        .serach_form_submit {
            width: 200px;
        }
    }
}

.search_results_list {
    padding-bottom:50px;

    ul {
        li {
            border:1px solid #eee;
            margin-bottom:10px;
            padding:10px;
            line-height:18px;

            a {
                color:#000;
                display: inline-block;
                width:100%;

                transition: ease 0.3s;

                &:hover {
                    padding-left:10px;
                }
            }

            span.date {
                font-size:12px;
            }
        }
    }
}

.myajaxlist_paginate_box {
    padding-top:50px!important;
    .one_page {
        padding-top:5px!important;
    }
}


.default_table {
    width:100%;
    thead {
        tr {
            th {
                padding:5px 10px;
                font-size:12px;
                line-height: 16px;
                border:1px solid #eee;
                background-color:#f7f7f7;
            }
        }
    }
    tr {
        td {
            padding:5px 10px;
            border:1px solid #eee;
            line-height:20px;
            font-size:14px;
        }
    }
}

.skiplinks{
    position: absolute;
    left: 0;
    top: 0;
    ul{
        li{
            a{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;
                &:focus{
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }
    a{
        background: rgba(0,0,0,0.8);
        color: $white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

.datepicker > div.datepicker-days {
    display:block;
}

@media only screen and (max-width: 991px) {
    .table_mobile_holder{
        overflow-x: scroll;
        margin-bottom: 40px;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: $accent_color1;
        }
        &::-webkit-scrollbar-track {
            background: $white;
        }
        table{
            width: 999px;
        }
    }
}
.desc_btn_holder{
    padding-top: 50px;
}

.video_frame {display:none;}

.video_parent_box {
    position:relative;
    width:100%;

    .video_frame {
        display:block;
    }

    video {
        width:100%!important;
        height:auto!important;
    }

    canvas {
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        top:0;
    }

    .video_play {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.7;
        }

        > div {
            width:100px;
            height:100px;

            svg {
                width:100px;
                height:100px;

                path {
                    fill:#fff;
                }
            }
        }
    }
}

.colspan_size{
    width: calc(100% - 50px);
}
